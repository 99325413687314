import { useTranslation } from 'react-i18next';

const Footer = () => {

  const { t } = useTranslation();

  return (
    <div className='w-full h-10 bg-[rgb(21,59,94)] bg-[linear-gradient(90deg,rgba(21,59,94,1)_0%,rgba(21,59,94,1)_81%,rgba(124,162,197,1)_91%,rgba(113,177,237,1)_100%)] flex justify-center items-center'>
      <p className="text-white">
        {t("footer.1")}
      </p>
    </div>
  )
}

export default Footer