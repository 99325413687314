import { createStore } from 'effector';
import { changeGlobalBrandFx, getBrandsFx } from 'features/brands/effects/brands';
import { BrandsEnum } from 'shared/constants/brands';

interface BrandState {
	selectedBrand: BrandsEnum;
	selectedBrandName: string,
	selectedBrandCountry: string
}

export const $brandsState = createStore<BrandState>({
	selectedBrand: BrandsEnum.NOBRANDS,
	selectedBrandName: "DESCAMPS",
	selectedBrandCountry: "FR",
});
$brandsState.on(changeGlobalBrandFx.doneData, (state, payload) => {
	return {
		...state,
		selectedBrandName: payload.name,
		selectedBrand: payload.id,
		selectedBrandCountry: payload.storeCountry
	}
})
export const $brandStore = createStore({})
$brandStore.on(getBrandsFx.doneData, (state, payload) => {
	return {
		...state,
		brandsStore: payload
	}
})
