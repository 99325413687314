export enum BrandsEnum {
  NOBRANDS = 0,
  DESCAMPS = 1,
  JALLA = 2,
  ZUCCHI = 3,
  BASSETTI = 4,
  STORE = 5,
  TOMMY = 6,
  WHOLESALE = 9,
  HYPSI = 10,
  BASSETTI_DE = 11,
  HYPSIP = 12,
  HYPSI_PARTNER = 13,
  WAVEUM = 21,
  MARKETPLACE = 22,
  WHOLESALER = 23,
  PORTHAULT = 24,
}